import React from "react";
import { Button } from "../ui/button";
import { Link } from "react-router-dom";

const Products = () => {
  const products = [
    {
      productname: "Rooftop Solar",
      desc: "Solar panels installed on rooftops to generate electricity for residential or commercial buildings.",
      icon: "https://bsmedia.business-standard.com/_media/bs/img/article/2021-12/24/full/1640336917-2305.jpg",
    },
    {
      productname: "Off-grid Solar",
      desc: "Standalone solar systems that operate independently of the grid, ideal for remote areas.",
      icon: "https://i0.wp.com/suvastika.com/new/wp-content/uploads/2023/08/5d136a15-aad7-49f7-a895-208455df0133-1-e1693276005814.jpg?fit=1030%2C501&ssl=1",
    },
    {
      productname: "On-grid Solar",
      desc: "Solar systems connected to the power grid, allowing excess electricity to be sold back to the utility company.",
      icon: "https://kenbrooksolar.com/wp-content/uploads/On-Grid-Solar-Panel-System-Price-List.jpg",
    },
    {
      productname: "Solar Inverter",
      desc: "Converts the direct current (DC) generated by solar panels into alternating current (AC) for use in homes or businesses",
      icon: "https://cdnbbsr.s3waas.gov.in/s3f80ff32e08a25270b5f252ce39522f72/uploads/2023/02/2023020922.jpg",
    },
    {
      productname: "Solar Battery",
      desc: "Stores excess energy produced by solar panels for later use, ensuring power availability during outages",
      icon: "https://5.imimg.com/data5/DC/VS/MY-64895474/solar-panel-battery.jpg",
    },
    {
      productname: "Solar Pump",
      desc: "A solar-powered water pumping system, often used in agriculture for irrigation and water supply.",
      icon: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRnybHLDP2OaZJnsHWnjVFqcryT0joPYLdsAg&s",
    },
  ];
  return (
    <>
      <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
        <div className="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
          <h2 className="max-w-lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-gray-900 sm:text-4xl md:mx-auto">
            <span className="relative inline-block">Our Products</span>
          </h2>
          <p className="text-base text-gray-700 md:text-lg">
            Sed ut perspiciatis unde omnis iste natus error sit voluptatem
            accusantium doloremque rem aperiam, eaque ipsa quae.
          </p>
        </div>
        <div className="grid gap-5 mb-8 md:grid-cols-2 lg:grid-cols-3">
          {products.map((product, index) => (
            <div key={index}>
              <div className="p-5 duration-300 transform bg-white border rounded-md shadow-sm hover:-translate-y-2">
                <img
                  className="rounded md:rounded-md mb-4 h-[200px] md:h-[260px] w-full object-cover object-center"
                  src={product.icon}
                  alt=""
                />
                <h6 className="mb-2 text-xl font-bold leading-5">
                  {product.productname}
                </h6>
                <p className="mb-3 text-sm text-gray-900">{product.desc}</p>
              </div>
            </div>
          ))}
        </div>
        <Link
          to={"/products"}
          className="text-center w-full flex md:justify-center">
          <Button>Explore More -&gt;</Button>
        </Link>
      </div>
    </>
  );
};

export default Products;
