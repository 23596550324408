import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import {
  Sun,
  Battery,
  Zap,
  ArrowRight,
  Plus,
  Check,
  Download,
  FileText,
} from "lucide-react";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";

const products = [
  {
    id: 1,
    category: "Solar Panels",
    items: [
      {
        name: "Rooftop Solar",
        description:
          "Solar panels installed on rooftops to generate electricity for residential or commercial buildings.",
        image:
          "https://bsmedia.business-standard.com/_media/bs/img/article/2021-12/24/full/1640336917-2305.jpg",
        icon: Sun,
        features: [
          "High efficiency conversion rate",
          "Weather-resistant design",
          "25-year warranty",
          "Easy maintenance",
          "Smart monitoring system",
          "Aesthetic design",
        ],
        specifications: {
          "Power Output": "400W-600W",
          Efficiency: "21.5%",
          "Cell Type": "Monocrystalline",
          Warranty: "25 Years",
          "Temperature Range": "-40°C to 85°C",
          Dimensions: "1755 × 1038 × 35mm",
        },
        benefits: [
          {
            title: "Cost Savings",
            description: "Reduce electricity bills by up to 70%",
          },
          {
            title: "Environmental Impact",
            description: "Zero emissions and clean energy generation",
          },
          {
            title: "Low Maintenance",
            description: "Minimal upkeep required over 25+ years",
          },
        ],
      },
      {
        name: "Off-grid Solar",
        description:
          "Standalone solar systems that operate independently of the grid, ideal for remote areas.",
        image:
          "https://i0.wp.com/suvastika.com/new/wp-content/uploads/2023/08/5d136a15-aad7-49f7-a895-208455df0133-1-e1693276005814.jpg?fit=1030%2C501&ssl=1",
        icon: Sun,
        features: [
          "Complete energy independence",
          "Battery storage system",
          "Backup power capability",
          "Scalable design",
          "Remote monitoring",
          "All-weather performance",
        ],
        specifications: {
          "System Capacity": "1kW-10kW",
          "Battery Storage": "5kWh-20kWh",
          "Inverter Type": "Off-grid",
          Warranty: "20 Years",
          "Installation Area": "Flexible",
          Monitoring: "Remote System",
        },
        benefits: [
          {
            title: "Energy Independence",
            description: "Complete freedom from grid dependency",
          },
          {
            title: "Remote Area Solution",
            description: "Perfect for areas without grid access",
          },
          {
            title: "Reliable Power",
            description: "24/7 power availability with battery backup",
          },
        ],
      },
      {
        name: "On-grid Solar",
        description:
          "Solar systems connected to the power grid, allowing excess electricity to be sold back to the utility company.",
        image:
          "https://kenbrooksolar.com/wp-content/uploads/On-Grid-Solar-Panel-System-Price-List.jpg",
        icon: Sun,
        features: [
          "Grid synchronization",
          "Net metering capability",
          "High efficiency panels",
          "Smart grid integration",
          "Export excess power",
          "Real-time monitoring",
        ],
        specifications: {
          "System Size": "3kW-100kW",
          "Grid Connection": "3-Phase/1-Phase",
          Metering: "Bi-directional",
          Warranty: "25 Years",
          Efficiency: "98.5%",
          "Response Time": "<0.5s",
        },
        benefits: [
          {
            title: "Sell Excess Power",
            description: "Earn by selling surplus energy to the grid",
          },
          {
            title: "Grid Backup",
            description: "Reliable grid power when needed",
          },
          {
            title: "Lower Investment",
            description: "No battery storage required",
          },
        ],
      },
    ],
  },
  {
    id: 2,
    category: "Power Equipment",
    items: [
      {
        name: "Solar Inverter",
        description:
          "Converts the direct current (DC) generated by solar panels into alternating current (AC) for use in homes or businesses",
        image:
          "https://cdnbbsr.s3waas.gov.in/s3f80ff32e08a25270b5f252ce39522f72/uploads/2023/02/2023020922.jpg",
        icon: Zap,
        features: [
          "High conversion efficiency",
          "Multiple MPPT channels",
          "Advanced grid protection",
          "Smart monitoring",
          "Wide voltage range",
          "IP65 protection",
        ],
        specifications: {
          "Power Rating": "1kW-50kW",
          Efficiency: "98.2%",
          "Input Voltage": "200-1000V DC",
          Output: "230V/415V AC",
          "Protection Grade": "IP65",
          Warranty: "10 Years",
        },
        benefits: [
          {
            title: "Efficient Conversion",
            description: "Maximum power utilization from solar panels",
          },
          {
            title: "Smart Features",
            description: "Advanced monitoring and protection",
          },
          {
            title: "Reliable Operation",
            description: "Stable performance in all conditions",
          },
        ],
      },
      {
        name: "Solar Battery",
        description:
          "Stores excess energy produced by solar panels for later use, ensuring power availability during outages",
        image:
          "https://5.imimg.com/data5/DC/VS/MY-64895474/solar-panel-battery.jpg",
        icon: Battery,
        features: [
          "Long cycle life",
          "Deep discharge capability",
          "Temperature controlled",
          "Low maintenance",
          "High energy density",
          "Smart BMS system",
        ],
        specifications: {
          Capacity: "5kWh-20kWh",
          Voltage: "48V",
          "Cycle Life": "6000+ cycles",
          DoD: "95%",
          Warranty: "10 Years",
          Chemistry: "Lithium Iron Phosphate",
        },
        benefits: [
          {
            title: "Energy Storage",
            description: "Store excess solar power for night use",
          },
          {
            title: "Backup Power",
            description: "Reliable power during grid outages",
          },
          {
            title: "Peak Shaving",
            description: "Reduce peak power consumption costs",
          },
        ],
      },
      {
        name: "Solar Pump",
        description:
          "A solar-powered water pumping system, often used in agriculture for irrigation and water supply.",
        image:
          "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRnybHLDP2OaZJnsHWnjVFqcryT0joPYLdsAg&s",
        icon: Zap,
        features: [
          "Maintenance-free operation",
          "No fuel costs",
          "Remote monitoring",
          "Variable speed control",
          "Dry run protection",
          "Auto start/stop",
        ],
        specifications: {
          "Power Range": "1HP-20HP",
          "Max Head": "100-600ft",
          "Flow Rate": "1000-100000 LPD",
          "Input Voltage": "DC",
          Protection: "IP68",
          Warranty: "5 Years",
        },
        benefits: [
          {
            title: "Zero Operating Cost",
            description: "No electricity or fuel expenses",
          },
          {
            title: "Reliable Irrigation",
            description: "Consistent water supply for farming",
          },
          {
            title: "Low Maintenance",
            description: "Minimal upkeep required",
          },
        ],
      },
    ],
  },
];

const ProductDetailDialog = ({ product }) => (
  <Dialog>
    <DialogTrigger asChild>
      <motion.button
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
        className="text-[#009a8d] font-medium flex items-center gap-2">
        Learn More <ArrowRight className="w-4 h-4" />
      </motion.button>
    </DialogTrigger>
    <DialogContent className="max-w-2xl max-h-[90vh] overflow-y-auto">
      <DialogHeader>
        <DialogTitle className="text-2xl font-bold">{product.name}</DialogTitle>
        <DialogDescription>
          Discover the features and benefits of our {product.name}
        </DialogDescription>
      </DialogHeader>

      <div className="mt-4 space-y-6">
        <img
          src={product.image}
          alt={product.name}
          className="w-full h-64 object-cover rounded-lg"
        />

        <div className="space-y-4">
          <h3 className="font-semibold text-lg">Key Features</h3>
          <div className="grid grid-cols-2 gap-3">
            {product.features.map((feature, index) => (
              <div key={index} className="flex items-center gap-2">
                <Check className="w-4 h-4 text-[#009a8d]" />
                <span className="text-sm">{feature}</span>
              </div>
            ))}
          </div>
        </div>

        <div className="space-y-4">
          <h3 className="font-semibold text-lg">Technical Specifications</h3>
          <div className="grid grid-cols-2 gap-4">
            {Object.entries(product.specifications).map(([key, value]) => (
              <div key={key} className="flex flex-col">
                <span className="text-sm text-gray-500">{key}</span>
                <span className="font-medium">{value}</span>
              </div>
            ))}
          </div>
        </div>

        <div className="space-y-4">
          <h3 className="font-semibold text-lg">Benefits</h3>
          <div className="grid gap-4">
            {product.benefits.map((benefit, index) => (
              <div key={index} className="bg-gray-50 p-4 rounded-lg">
                <h4 className="font-medium text-[#009a8d]">{benefit.title}</h4>
                <p className="text-sm text-gray-600">{benefit.description}</p>
              </div>
            ))}
          </div>
        </div>

        <div className="flex justify-between pt-4">
          <button className="flex items-center gap-2 text-sm text-gray-600 hover:text-[#009a8d]">
            <Download className="w-4 h-4" />
            Download Brochure
          </button>
          <button className="flex items-center gap-2 text-sm text-gray-600 hover:text-[#009a8d]">
            <FileText className="w-4 h-4" />
            Technical Details
          </button>
        </div>
      </div>
    </DialogContent>
  </Dialog>
);

const ProductCard = ({ product }) => (
  <motion.div
    initial={{ opacity: 0, y: 20 }}
    animate={{ opacity: 1, y: 0 }}
    exit={{ opacity: 0, y: -20 }}
    className="bg-white rounded-xl shadow-lg overflow-hidden group">
    <div className="relative h-64 overflow-hidden">
      <img
        src={product.image}
        alt={product.name}
        className="w-full h-full object-cover group-hover:scale-105 transition-transform duration-500"
      />
      <div className="absolute inset-0 bg-gradient-to-t from-black/60 to-transparent opacity-0 group-hover:opacity-100 transition-opacity" />
    </div>

    <div className="p-6">
      <h3 className="text-xl font-bold mb-2 group-hover:text-[#009a8d] transition-colors">
        {product.name}
      </h3>
      <p className="text-gray-600 text-sm mb-4 line-clamp-2">
        {product.description}
      </p>

      <div className="space-y-3">
        {product.features.slice(0, 3).map((feature, index) => (
          <div
            key={index}
            className="flex items-center gap-2 text-sm text-gray-600">
            <Check className="w-4 h-4 text-[#009a8d]" />
            {feature}
          </div>
        ))}
      </div>

      <div className="mt-6">
        <ProductDetailDialog product={product} />
      </div>
    </div>
  </motion.div>
);

const ProductsPage = () => {
  const [selectedCategory, setSelectedCategory] = useState("All");
  const getAllProducts = () => {
    return products.reduce((allItems, category) => {
      return [...allItems, ...category.items];
    }, []);
  };
  const getCurrentProducts = () => {
    if (selectedCategory === "All") {
      return getAllProducts();
    }
    return (
      products.find((cat) => cat.category === selectedCategory)?.items || []
    );
  };
  return (
    <div className="min-h-screen bg-white">
      {/* Hero Section */}
      <div className="bg-[#009a8d] text-white py-16">
        <div className="max-w-7xl mx-auto px-4">
          <div className="text-center">
            <motion.h1
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              className="text-4xl md:text-5xl font-bold mb-6">
              Our Solar Solutions
            </motion.h1>
            <motion.p
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.1 }}
              className="text-xl text-gray-100 max-w-2xl mx-auto">
              Discover our comprehensive range of solar products designed for
              efficiency, reliability, and sustainability.
            </motion.p>
          </div>
        </div>
      </div>

      {/* Products Section */}
      <div className="max-w-7xl mx-auto px-4 py-16">
        <Tabs defaultValue={selectedCategory} className="space-y-8">
          <div className="flex justify-center">
            <TabsList>
              <TabsTrigger
                value="All"
                onClick={() => setSelectedCategory("All")}>
                All Products
              </TabsTrigger>
              {products.map((category) => (
                <TabsTrigger
                  key={category.id}
                  value={category.category}
                  onClick={() => setSelectedCategory(category.category)}>
                  {category.category}
                </TabsTrigger>
              ))}
            </TabsList>
          </div>

          <TabsContent value="All">
            <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
              {getAllProducts().map((product) => (
                <ProductCard key={product.name} product={product} />
              ))}
            </div>
          </TabsContent>

          {products.map((category) => (
            <TabsContent key={category.id} value={category.category}>
              <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
                {category.items.map((product) => (
                  <ProductCard key={product.name} product={product} />
                ))}
              </div>
            </TabsContent>
          ))}
        </Tabs>
      </div>

      {/* CTA Section */}
      <div className="bg-[#009a8d]/10 py-16">
        <div className="max-w-7xl mx-auto px-4 text-center">
          <h2 className="text-3xl font-bold mb-6">Need Help Choosing?</h2>
          <p className="text-gray-600 max-w-2xl mx-auto mb-8">
            Our experts are here to help you find the perfect solar solution for
            your needs. Get in touch for a free consultation.
          </p>
          <motion.button
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            className="bg-[#009a8d] text-white px-8 py-3 rounded-lg font-medium hover:bg-[#008075] transition-colors">
            Contact Us
          </motion.button>
        </div>
      </div>
    </div>
  );
};

export default ProductsPage;
